<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-data-table
        :headers="table1.headers"
        :items="table1.items"
        :loading="table1.loading"
        hide-default-footer
        :page.sync="pageTable1"
        :items-per-page="table1.itemsPerPage"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-bold">Permintaan Barang</v-toolbar-title>
          </v-toolbar>
          <v-form class="ml-4">
            <v-row>
              <v-col cols="1" md="1">
                <label class="font-weight-black" for="firstname">Filter</label>
              </v-col>
              <v-col cols="3" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="table1.search"
                  label="Jenis/Merk"
                  @change="optionChange()"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:item.foto="{ item }">
          <v-img
            style="margin: 5px"
            max-width="200"
            max-height="150"
            :src="`data:image/png;base64, ${item.foto}`"
            :lazy-src="require('@/assets/images/logos/logo.png')"
          ></v-img>
        </template>
        <!-- <template v-slot:item.isi="{ item }">
          {{ item.isi.substring(0, 50) + ' ...' }}
        </template> -->
        <template v-slot:item.aksi="{ item }">
          <v-icon class="mr-2 bedit" @click="openDialogDetail(item)"> {{ icons.mdiEye }} </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getTable1"> Reload </v-btn>
        </template>
      </v-data-table>
      <div class="px-4 py-4 white">
        <v-row>
          <v-col cols="6">
            <v-select
              label="Jumlah per halaman"
              :items="table1.length_menus"
              hide-details
              outlined
              dense
              v-model="table1.itemsPerPage"
              @change="optionChange()"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-pagination v-model="pageTable1" :length="table1.pageCount" :total-visible="3"></v-pagination>
          </v-col>
        </v-row>
      </div>
      <v-dialog max-width="1024px" v-model="table1.dialogDetail">
        <v-card>
          <v-card-title>
            <span class="text-h5">Detail Barang</span>
          </v-card-title>
          <v-card-text v-if="Object.keys(table1.itemDetail).length !== 0" >
            <h3>Data Pesanan</h3>

            <v-simple-table dense style="width: 100%">
              <tbody>
                <tr>
                  <td><b>Foto</b></td>
                  <td>:</td>
                  <td>
                    <div v-if="table1.itemDetail.previewImage != ''" style="border: 1px solid grey; padding: 5px">
                      <v-img max-width="600" max-height="400" :src="table1.itemDetail.previewImage"></v-img>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><b>Jenis</b></td>
                  <td>:</td>
                  <td>{{ table1.itemDetail.jenis_barang }}</td>
                </tr>
                <tr>
                  <td><b>Merk</b></td>
                  <td>:</td>
                  <td>{{ table1.itemDetail.merk_barang }}</td>
                </tr>
                <tr>
                  <td><b>Deskripsi</b></td>
                  <td>:</td>
                  <td>{{ table1.itemDetail.deskripsi }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <h3 class="mt-4">Pemesan</h3>
            <v-simple-table dense style="width: 100%">
              <tbody>
                <tr>
                  <td><b>Kode Anggota</b> : {{ table1.itemDetail.kode_anggota }}</td>
                  <td><b>Nama</b> : {{ table1.itemDetail.nama_anggota }}</td>
                  <td>
                    <b>Wilayah</b> :
                    <span v-if="table1.itemDetail.wilayah_luar === '1'" style="color: red">{{ table1.itemDetail.wilayah }}</span>
                    <span v-else>{{ table1.itemDetail.wilayah }}</span>
                  </td>
                </tr>
                <tr>
                  <td><b>Tgl Lahir</b> : {{ formatDate(table1.itemDetail.tgl_lahir) }}</td>
                  <td><b>Divisi</b> : {{ table1.itemDetail.divisi }}</td>
                  <td><b>Jenis Upah</b> : {{ table1.itemDetail.jenis_upah }}</td>
                </tr>
                <tr>
                  <td><b>TGL Masuk</b> : {{ formatDate(table1.itemDetail.tgl_masuk) }}</td>
                  <td><b>Jabatan</b> : {{ table1.itemDetail.jabatan }}</td>
                  <td><b>Jumlah Upah : </b>{{ formatMoney(table1.itemDetail.jumlah_upah) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import { mdiPencil, mdiDelete, mdiEye } from '@mdi/js'
import { reactive } from '@vue/composition-api'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    const table1 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Foto', value: 'foto', sortable: false },
        { text: 'Jenis', value: 'jenis_barang', sortable: false },
        { text: 'Merk', value: 'merk_barang', sortable: false, align: 'right' },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      length_menus: [10, 25, 50],
      dialogDetail: false,
      itemDetail: {},
    })
    function getTable1() {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable1)
      form.append('itemsPerPage', this.table1.itemsPerPage)
      form.append('search', this.table1.search)
      axios
        .post(`${apiRoot}/api/Barang/getRequestedBarangsTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.items = response.data.data
            this.table1.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    }

    return {
      table1,
      getTable1,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
    }
  },
  data: () => ({
    pageTable1: 1,
    rules: {
      required: value => !!value || 'Diwajibkan.',
      image: [value => !value || value.size < 2000000 || 'Ukuran tidak boleh lebih dari 2 MB!'],
    },
  }),
  computed: {
    formTitle() {
      return this.table1.editedIndex === -1 ? 'Tambah' : 'Edit'
    },
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    pageTable1: {
      handler() {
        this.getTable1()
      },
      deep: true,
    },
  },
  created() {
    // this.$store.state.page_title = 'Master & Pengaturan'
    this.getTable1()
  },
  methods: {
    optionChange() {
      this.page = 1
      this.getTable1()
    },
    openDialogDetail(item) {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_request', item.id)
      axios
        .post(`${apiRoot}/api/Barang/getDetailRequestedBarang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.itemDetail=Object.assign({}, response.data.data)
            this.table1.itemDetail.previewImage = `data:image/png;base64, ${item.foto}`
            this.table1.itemDetail.foto = ''
            this.table1.dialogDetail = true
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    openDialogDelete(item) {
      // this.table1.editedIndex = this.table1.items.indexOf(item)
      // Object.assign(this.table1.itemEdit, item)
      // this.table1.dialogDelete = true
      let conf = confirm('Apakah yakin akan dihapus?')
      if (conf) {
        this.deleteItemConfirmed(item)
      }
    },
    deleteItemConfirmed(item) {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id', item.id)
      axios
        .post(`${apiRoot}/api/Barang/hapusedBarang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.optionChange()
            this.getTable1()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    close() {
      this.table1.dialog = false
      this.$nextTick(() => {
        this.table1.itemEdit=Object.assign({}, this.defaultItem)
        this.table1.editedIndex = -1
      })
    },
    save() {
      if (this.table1.editedIndex > -1) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.table1.itemEdit.id)
        form.append('image', this.table1.itemEdit.foto)
        form.append('nama_barang', this.table1.itemEdit.nama_barang)
        form.append('detail', this.table1.itemEdit.detail)
        form.append('deskripsi', this.table1.itemEdit.deskripsi)
        form.append('harga', this.table1.itemEdit.harga)
        form.append('qty', this.table1.itemEdit.qty)
        axios
          .post(`${apiRoot}/api/Barang/editBarang`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionChange()
              this.getTable1()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('image', this.table1.itemEdit.foto)
        form.append('nama_barang', this.table1.itemEdit.nama_barang)
        form.append('detail', this.table1.itemEdit.detail)
        form.append('deskripsi', this.table1.itemEdit.deskripsi)
        form.append('harga', this.table1.itemEdit.harga)
        form.append('qty', this.table1.itemEdit.qty)
        axios
          .post(`${apiRoot}/api/Barang/addBarang`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionChange()
              this.getTable1()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    selectImage() {
      // console.log( this.table1.itemEdit.foto)
      this.table1.itemEdit.foto = this.$refs.file.files.item(0)
      // console.log( this.table1.itemEdit.foto)
      this.table1.itemEdit.previewImage = URL.createObjectURL(this.table1.itemEdit.foto)
    },
    getDetail(item) {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_barang', item.id)
      axios
        .post(`${apiRoot}/api/Barang/getDetailBarang`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.itemSelected=Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    },
  },
}
</script>
